import React, { useState, useRef, useEffect } from "react"
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import ProgressBar from "../progress-bar/review_proposal_progress_bar";
import { REVIEW_PROPOSAL_STEP } from "../progress-bar/constants";
import {submitProposalReview} from "../../api/sage";
import ProposalImg from '../../images/review-form/proposal.png';
import ExampleImg from '../../images/review-form/example.png';
import ReviewFormImg from '../../images/review-form/review.png';

const PROPOSAL_PDF_COLUMN_NAME = 'Proposal PDF';
const PROPOSAL_GUIDANCE_COLUMN_NAME = 'Guidance';
const REVIEW_FORM_COLUMN_NAME = 'Review form';
const PM_COLUMN_NAME = 'PM Email';

export const Review = ({ formSubmitCb, expert, proposal, token, profile }) => {
    const { handleSubmit} = useForm();
    const [state, setState] = useState({
        isSubmitted: false,
        isError: false,
        error: '',
      });

    const formRef = useRef(null);

    const submitProposalReviewMutation = useMutation(() => {
        return submitProposalReview(token);
    });

    const onSubmit = (data) => {
        submitProposalReviewMutation.mutate();
    }

    useEffect(() => {
        if (submitProposalReviewMutation.isSuccess) {
            formSubmitCb();
        }
    }, [submitProposalReviewMutation.isSuccess]);

    return (
        <>
            <div className="c-proposal-review" ref={formRef}>
                <div className="container">
                    <div className="row">
                        <div className="c-proposal-review__form">
                            <ProgressBar currentStep={REVIEW_PROPOSAL_STEP} />
                            <div className="f-section">
                                <h3>Proposal Review</h3>
                                <p><br/>Hello {profile['first_name']}, <br/><br/>
                                    The proposal for your review is available below, along with some background information and the review form itself. An explanation of the funding programme aims and guidance on the evaluation criteria is available within the review form. </p>

                                <p>&nbsp;</p>
                                <p>
                                    If you have any questions, please get in touch with me
                                    (<a href={`mailto:${proposal[PM_COLUMN_NAME]}`} target='_blank'>{proposal[PM_COLUMN_NAME]}</a>)
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="c-blog-listing c-blog-listing--featured proposal-documents">
                        <div className="row">
                            <div className="c-blog-listing__item">
                                <a className="c-blog-listing__item-link is-inview" href={proposal[PROPOSAL_PDF_COLUMN_NAME]} target="_blank">
                                    <div className="c-blog-listing__item-media-container">
                                        <div className="c-blog-listing__item-media-wrapper">
                                            <img src={ProposalImg} alt="" className="c-blog-listing__item-media" loading="lazy"/>
                                        </div>
                                    </div>
                                    <h3 className="c-blog-listing__item-title">Proposal</h3>
                                </a>
                            </div>

                            <div className="c-blog-listing__item">
                                <a className="c-blog-listing__item-link is-inview" href={proposal[PROPOSAL_GUIDANCE_COLUMN_NAME]} target="_blank">
                                    <div className="c-blog-listing__item-media-container">
                                        <div className="c-blog-listing__item-media-wrapper">
                                            <img src={ExampleImg} alt="" className="c-blog-listing__item-media" loading="lazy"/>
                                        </div>
                                    </div>
                                    <h3 className="c-blog-listing__item-title">Background</h3>
                                </a>
                            </div>

                            <div className="c-blog-listing__item">
                                <a className="c-blog-listing__item-link is-inview" href={expert[REVIEW_FORM_COLUMN_NAME]} target="_blank">
                                    <div className="c-blog-listing__item-media-container">
                                        <div className="c-blog-listing__item-media-wrapper">
                                            <img src={ReviewFormImg} alt="" className="c-blog-listing__item-media" loading="lazy"/>
                                        </div>
                                    </div>
                                    <h3 className="c-blog-listing__item-title">Review form</h3>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="f-section">
                        <div className="row">
                            <div className="c-proposal-review__form">
                            <p>
                                Once done, click below to submit your review.
                            </p>
                            <form className="c-form" onSubmit={handleSubmit(onSubmit)}>
                                    {state.isError && (
                                      <div className="f-messages">
                                          <div className="f-messages__alert f-messages__alert--error">
                                              <p>{state.error}</p>
                                          </div>
                                      </div>
                                    )}

                                    <div className="row">
                                        <div className="f-field f-field--full">
                                            <button
                                                type="submit"
                                                className="c-btn c-btn--large c-btn--primary"
                                            >
                                                SUBMIT
                                            </button>
                                            {submitProposalReviewMutation.isLoading && <p>Loading...</p>}
                                            {submitProposalReviewMutation.isError && <span className="f-error">An error has occurred. Please try again.</span>}
                                        </div>
                                    </div>
                                </form>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Review;