import React, {useState, useRef} from "react"
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import {useMutation} from "react-query";
import Select from "../fields/select";
import {
    RECIPIENT_BANK_UK_SORT_CODE, RECIPIENT_BANK_SWIFTBIC, RECIPIENT_BANK_IBAN,
    CURRENCIES, COUNTRY_CHOICES, RECIPIENT_BANK_ACH_ROUTING, ACH_TYPE_CHECKING, ACH_TYPE_SAVING
} from "../../constants";
import ProgressBar from "../progress-bar/review_proposal_progress_bar";
import { PAYMENT_DETAILS_STEP } from "../progress-bar/constants";
import {email, required} from "../../utils/validators";
import {savePaymentDetails} from "../../api/associates";

const SelectField = React.forwardRef((props,ref) => {
    return <Select  {...props} />
});

const PM_COLUMN_NAME = 'PM Email';

export const AdvisorRequestPayment = ({ profile, formSubmitCb, proposal }) => {
    const { register, handleSubmit, control, formState: { errors }, setError, watch, getValues } = useForm();
    const [state, setState] = useState({
        isSubmitted: false,
        isError: false,
        error: '',
    });

    const watchRecipientBank = watch('recipient_bank');

    const recipientBankOptions = [
        {
          key: RECIPIENT_BANK_UK_SORT_CODE,
          text: 'SORT code',
          value: RECIPIENT_BANK_UK_SORT_CODE,
        },
        {
          key: RECIPIENT_BANK_IBAN,
          text: 'IBAN',
          value: RECIPIENT_BANK_IBAN,
        },
        {
          key: RECIPIENT_BANK_SWIFTBIC,
          text: 'SWIFT code',
          value: RECIPIENT_BANK_SWIFTBIC,
        },
        {
          key: RECIPIENT_BANK_ACH_ROUTING,
          text: 'ACH Routing',
          value: RECIPIENT_BANK_ACH_ROUTING,
        },
    ];

    const achTypeOptions = {};
    achTypeOptions[ACH_TYPE_CHECKING] = 'Checking';
    achTypeOptions[ACH_TYPE_SAVING] = 'Saving';

    const formRef = useRef(null);

    const saveExpertPaymentMutation = useMutation({
        mutationKey: ['save-expert-payment', profile?.id],
        mutationFn: (data) => {
            return savePaymentDetails(profile?.id || null, data);
        },
        onSuccess: async (response) => {
            formSubmitCb();
        },
        onError: (error) => {
            if (error?.response['expert']) {
                let focus = true;
                for(let fieldName in error.response['expert']) {
                    setError(fieldName, {type: '', message: error.response['expert'][fieldName][0]},
                        {shouldFocus: focus});
                    focus = false;
                }
            } else {
                formRef.current.scrollIntoView();
            }
        }
    });

    const onSubmit = (data) => {
        setState({
            isError: false,
        });
        let shouldFocus = true;
        let isError = false;

        const {
            paypal_email_address: paypalEmailAddress,
            recipient_bank: recipientBank,
            account_name: accountName,
            currency,
            paypal_currency: paypalCurrency,
            country,
            account_number: accountNumber,
            sort_code: sortCode,
            swiftbic,
            recipient_address,
            recipient_city,
            recipient_postal_code,
            recipient_state,
            ach_type: achType,
            ach_routing: achRouting,
          } = getValues();

        if (!paypalEmailAddress && !recipientBank) {
            formRef.current.scrollIntoView();

            setState({
                isError: true,
                error: 'Please provide at least one method of payment'
            });
            isError = true;
        }

        if (paypalEmailAddress &&
            (email(paypalEmailAddress) || required(paypalCurrency))
        ) {
            if (email(paypalEmailAddress)) {
                setError('paypal_email_address', {'message': email(paypalEmailAddress)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }

            if (required(paypalCurrency)) {
                setError('paypal_currency', {'message': required(paypalCurrency)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }
        }

        if (
            recipientBank === RECIPIENT_BANK_UK_SORT_CODE &&
            (required(accountName)
                || required(accountNumber)
                || required(sortCode)
            )
        ) {
            if (required(accountName)) {
                setError('account_name', {'message': required(accountName)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }

            if (required(accountNumber)) {
                setError('account_number', {'message': required(accountNumber)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }

            if (required(sortCode)) {
                setError('sort_code', {'message': required(sortCode)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }
        }

        if (
            recipientBank === RECIPIENT_BANK_SWIFTBIC &&
            (required(accountName)
                || required(currency)
                || required(country)
                || required(accountNumber)
                || required(swiftbic)
                || required(recipient_address)
                || required(recipient_city)
                || required(recipient_postal_code)
            )
        ) {
            if (required(accountName)) {
                setError('account_name', {'message': required(accountName)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }

            if (required(currency)) {
                setError('currency', {'message': required(currency)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }

            if (required(country)) {
                setError('country', {'message': required(country)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }

            if (required(accountNumber)) {
                setError('account_number', {'message': required(accountNumber)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }

            if (required(swiftbic)) {
                setError('swiftbic', {'message': required(swiftbic)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }

            if (required(recipient_address)) {
                setError('recipient_address', {'message': required(recipient_address)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }

            if (required(recipient_city)) {
                setError('recipient_city', {'message': required(recipient_city)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }

            if (required(recipient_postal_code)) {
                setError('recipient_postal_code', {'message': required(recipient_postal_code)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }
        }

        if (
            recipientBank === RECIPIENT_BANK_ACH_ROUTING &&
            (required(accountName)
                || required(currency)
                || required(country)
                || required(accountNumber)
                || required(achType)
                || required(achRouting)
                || required(recipient_address)
                || required(recipient_city)
                || required(recipient_postal_code)
                || required(recipient_state)
            )
        ) {
            if (required(accountName)) {
                setError('account_name', {'message': required(accountName)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }

            if (required(currency)) {
                setError('currency', {'message': required(currency)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }

            if (required(country)) {
                setError('country', {'message': required(country)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }

            if (required(accountNumber)) {
                setError('account_number', {'message': required(accountNumber)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }

            if (required(achType)) {
                setError('ach_type', {'message': required(achType)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }

            if (required(achRouting)) {
                setError('ach_routing', {'message': required(achRouting)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }

            if (required(recipient_address)) {
                setError('recipient_address', {'message': required(recipient_address)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }

            if (required(recipient_city)) {
                setError('recipient_city', {'message': required(recipient_city)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }

            if (required(recipient_postal_code)) {
                setError('recipient_postal_code', {'message': required(recipient_postal_code)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }

            if (required(recipient_state)) {
                setError('recipient_state', {'message': required(recipient_state)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }
        }

        if (
            recipientBank === RECIPIENT_BANK_IBAN &&
            (required(accountName)
                || required(currency)
                || required(country)
                || required(accountNumber)
            )
        ) {
            if (required(accountName)) {
                setError('account_name', {'message': required(accountName)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }

            if (required(currency)) {
                setError('currency', {'message': required(currency)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }

            if (required(country)) {
                setError('country', {'message': required(country)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }

            if (required(accountNumber)) {
                setError('account_number', {'message': required(accountNumber)}, { shouldFocus: shouldFocus });
                shouldFocus = false;
                isError = true;
            }
        }

        if (isError) {
            return;
        }

        saveExpertPaymentMutation.mutate(data);
    }

    return (
        <>
            <div className="c-proposal-review" ref={formRef}>
                <div className="container">
                    <div className="row">
                        <div className="c-proposal-review__form">
                            <ProgressBar currentStep={PAYMENT_DETAILS_STEP} />
                            <div className="f-section">
                                <form className="c-form" onSubmit={handleSubmit(onSubmit)}>
                                    <input type="hidden" name="payment_token" {...register("payment_token")}/>

                                    {saveExpertPaymentMutation.isError && (
                                      <div className="f-messages">
                                          <div className="f-messages__alert f-messages__alert--error">
                                              <p>{saveExpertPaymentMutation.error.response['error_message']}</p>
                                          </div>
                                      </div>
                                    )}

                                    {state.isError && (
                                      <div className="f-messages">
                                          <div className="f-messages__alert f-messages__alert--error">
                                              <p>{state.error}</p>
                                          </div>
                                      </div>
                                    )}


                                    {profile?.status === 3 ? (
                                        <>
                                            <p>Thank you. You have recently provided us with payment details, which we will keep on file for 30 days.</p>
                                            <p>&nbsp;</p>
                                            <p>Please get in touch with me (<a href={`mailto:${proposal[PM_COLUMN_NAME]}`} target='_blank' rel="noreferrer">{proposal[PM_COLUMN_NAME]}</a>) if you have any issues. </p>
                                            <p>&nbsp;</p>
                                            <button
                                                type="button"
                                                className="c-btn c-btn--large c-btn--primary"
                                                onClick={() => formSubmitCb()}
                                            >
                                                NEXT
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <h3>PayPal</h3>
                                            <p>Most honoraria payments are completed via PayPal. If you have an account, use this in preference to bank transactions. </p>
                                            <div className="row">
                                                <div className="f-field">
                                                    <div
                                                        className={classNames("f-field__elem", {"f-field__elem--has-error": errors.paypal_email_address})}>
                                                        <label htmlFor="paypal_email_address">PayPal email
                                                            address</label>
                                                        <input
                                                            name="paypal_email_address" className="f-control"
                                                            type="text"
                                                            placeholder="Please enter your PayPal email address"
                                                            {...register("paypal_email_address")}
                                                        />
                                                        {errors.paypal_email_address && <span
                                                            className="f-error">{errors.paypal_email_address.message}</span>}
                                                    </div>
                                                </div>

                                                <div className="f-field">
                                                    <div
                                                        className={classNames("f-field__elem", {"f-field__elem--has-error": errors.paypal_currency})}>
                                                        <label htmlFor="paypal_currency">Currency of your PayPal
                                                            wallet</label>

                                                        <Controller
                                                            control={control}
                                                            name="paypal_currency"
                                                            render={({field}) => <SelectField {...field}
                                                                                         data={CURRENCIES}/>}
                                                        />

                                                        {errors.paypal_currency && <span
                                                            className="f-error">{errors.paypal_currency.message}</span>}
                                                    </div>
                                                </div>
                                            </div>

                                            <p>&nbsp;</p>
                                            <h3>Bank Details</h3>
                                            <div className="row">
                                                <div className="f-field f-field--full">
                                                    <label htmlFor={"bank"}>Recipient Bank</label>

                                                    {recipientBankOptions.map(o => (
                                                        <div className="f-field__elem radio" key={`radio-${o.key}`}>
                                                            <input
                                                                type="radio"
                                                                name="recipient_bank"
                                                                className="f-control"
                                                                value={o.value}
                                                                id={o.key}
                                                                {...register('recipient_bank')}
                                                            />
                                                            <label key={o.key} htmlFor={o.key}>
                                                                {o.text}
                                                            </label>
                                                        </div>
                                                    ))}
                                                    {errors.recipient_bank && <span
                                                        className="f-error">{errors.recipient_bank.message}</span>}
                                                </div>

                                                {watchRecipientBank === RECIPIENT_BANK_IBAN &&
                                                    <>
                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.account_name})}>
                                                                <label htmlFor="account_name">Name on the
                                                                    account</label>
                                                                <input
                                                                    name="account_name" className="f-control"
                                                                    type="text"
                                                                    placeholder="Please enter name on the account"
                                                                    {...register("account_name")}
                                                                />
                                                                {errors.account_name && <span
                                                                    className="f-error">{errors.account_name.message}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="f-field">
                                                            &nbsp;
                                                        </div>

                                                        <div className="f-field f-field--full">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.account_number})}>
                                                                <label htmlFor="account_number">IBAN</label>
                                                                <input
                                                                    name="account_number" className="f-control"
                                                                    type="text"
                                                                    placeholder="Must start with two-letter country code"
                                                                    {...register("account_number")}
                                                                />
                                                                {errors.account_number && <span
                                                                    className="f-error">{errors.account_number.message}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.currency})}>
                                                                <label htmlFor="currency">Receiving currency</label>

                                                                <Controller
                                                                    control={control}
                                                                    name="currency"
                                                                    render={({field}) => <SelectField {...field}
                                                                                                 data={CURRENCIES}/>}
                                                                />

                                                                {errors.currency && <span
                                                                    className="f-error">{errors.currency.message}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.country})}>
                                                                <label htmlFor="country">Country of account</label>

                                                                <Controller
                                                                    control={control}
                                                                    name="country"
                                                                    render={({field}) => <SelectField {...field}
                                                                                                 data={COUNTRY_CHOICES}/>}
                                                                />

                                                                {errors.country && <span
                                                                    className="f-error">{errors.country.message}</span>}
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {watchRecipientBank === RECIPIENT_BANK_SWIFTBIC &&
                                                    <>
                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.account_name})}>
                                                                <label htmlFor="account_name">Name on the account</label>
                                                                <input
                                                                    name="account_name" className="f-control"
                                                                    type="text"
                                                                    placeholder="Please enter name on the account"
                                                                    {...register("account_name")}
                                                                />
                                                                {errors.account_name && <span
                                                                    className="f-error">{errors.account_name.message}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="f-field">
                                                            &nbsp;
                                                        </div>

                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.swiftbic})}>
                                                                <label htmlFor="swiftbic">BIC or SWIFT code</label>
                                                                <input
                                                                    name="swiftbic" className="f-control"
                                                                    type="text"
                                                                    placeholder="Must be between 8 and 11 characters"
                                                                    {...register("swiftbic")}
                                                                />
                                                                {errors.swiftbic && <span
                                                                    className="f-error">{errors.swiftbic.message}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.account_number})}>
                                                                <label htmlFor="account_number">Account
                                                                    number</label>
                                                                <input
                                                                    name="account_number" className="f-control"
                                                                    type="text"
                                                                    placeholder="Please enter your account number"
                                                                    {...register("account_number")}
                                                                />
                                                                {errors.account_number && <span
                                                                    className="f-error">{errors.account_number.message}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.currency})}>
                                                                <label htmlFor="currency">Receiving currency</label>

                                                                <Controller
                                                                    control={control}
                                                                    name="currency"
                                                                    render={({field}) => <SelectField {...field}
                                                                                                 data={CURRENCIES}/>}
                                                                />

                                                                {errors.currency && <span
                                                                    className="f-error">{errors.currency.message}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.country})}>
                                                                <label htmlFor="country">Country of account</label>

                                                                <Controller
                                                                    control={control}
                                                                    name="country"
                                                                    render={({field}) => <SelectField {...field}
                                                                                                 data={COUNTRY_CHOICES}/>}
                                                                />

                                                                {errors.country && <span
                                                                    className="f-error">{errors.country.message}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="f-field f-field--full">
                                                            <p>
                                                                To process SWIFT transactions via <a href="https://wise.com/" rel="noreferrer" target="_blank">Wise</a>, a recipient's
                                                                address is required. Please ensure this matches
                                                                the details on your account.
                                                            </p>
                                                        </div>

                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.recipient_address})}>
                                                                <label htmlFor="recipient_address">Recipient address</label>
                                                                <input
                                                                    name="recipient_address" className="f-control"
                                                                    type="text"
                                                                    placeholder="Eg. 123 Street Road"
                                                                    {...register("recipient_address")}
                                                                />
                                                                {errors.recipient_address && <span
                                                                    className="f-error">{errors.recipient_address.message}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.recipient_city})}>
                                                                <label htmlFor="recipient_city">City</label>
                                                                <input
                                                                    name="recipient_city" className="f-control"
                                                                    type="text"
                                                                    placeholder="Your City"
                                                                    {...register("recipient_city")}
                                                                />
                                                                {errors.recipient_city && <span
                                                                    className="f-error">{errors.recipient_city.message}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.recipient_postal_code})}>
                                                                <label htmlFor="recipient_postal_code">Postal Code</label>
                                                                <input
                                                                    name="recipient_postal_code" className="f-control"
                                                                    type="text"
                                                                    placeholder="Enter post or ZIP code"
                                                                    {...register("recipient_postal_code")}
                                                                />
                                                                {errors.recipient_postal_code && <span
                                                                    className="f-error">{errors.recipient_postal_code.message}</span>}
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {watchRecipientBank === RECIPIENT_BANK_ACH_ROUTING &&
                                                    <>
                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.account_name})}>
                                                                <label htmlFor="account_name">Name on the account</label>
                                                                <input
                                                                    name="account_name" className="f-control"
                                                                    type="text"
                                                                    placeholder="Please enter name on the account"
                                                                    {...register("account_name")}
                                                                />
                                                                {errors.account_name && <span
                                                                    className="f-error">{errors.account_name.message}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.ach_type})}>
                                                                <label htmlFor="ach_type">Checking or Saving</label>

                                                                <Controller
                                                                    control={control}
                                                                    name="ach_type"
                                                                    defaultValue={ACH_TYPE_CHECKING}
                                                                    render={({field}) => <SelectField {...field}
                                                                                                 data={achTypeOptions} />}
                                                                />

                                                                {errors.ach_type && <span
                                                                    className="f-error">{errors.ach_type.message}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.ach_routing})}>
                                                                <label htmlFor="swiftbic">ACH/ABA routing number</label>
                                                                <input
                                                                    name="ach_routing" className="f-control"
                                                                    type="text"
                                                                    placeholder="Must be 9 characters"
                                                                    {...register("ach_routing")}
                                                                />
                                                                {errors.ach_routing && <span
                                                                    className="f-error">{errors.ach_routing.message}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.account_number})}>
                                                                <label htmlFor="account_number">Account
                                                                    number</label>
                                                                <input
                                                                    name="account_number" className="f-control"
                                                                    type="text"
                                                                    placeholder="Please enter your account number"
                                                                    {...register("account_number")}
                                                                />
                                                                {errors.account_number && <span
                                                                    className="f-error">{errors.account_number.message}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.currency})}>
                                                                <label htmlFor="currency">Receiving currency</label>

                                                                <Controller
                                                                    control={control}
                                                                    name="currency"
                                                                    render={({field}) => <SelectField {...field}
                                                                                                 data={CURRENCIES}/>}
                                                                />

                                                                {errors.currency && <span
                                                                    className="f-error">{errors.currency.message}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.country})}>
                                                                <label htmlFor="country">Country of account</label>

                                                                <Controller
                                                                    control={control}
                                                                    name="country"
                                                                    render={({field}) => <SelectField {...field}
                                                                                                 data={COUNTRY_CHOICES}/>}
                                                                />

                                                                {errors.country && <span
                                                                    className="f-error">{errors.country.message}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="f-field f-field--full">
                                                            <p>
                                                                To process ACH/ABA Routing (Fedwire or CHIPS identifier) transactions via <a href="https://wise.com/" rel="noreferrer" target="_blank">Wise</a>, a recipient's address is required. Please ensure this matches the details on your account.
                                                            </p>
                                                        </div>

                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.recipient_address})}>
                                                                <label htmlFor="recipient_address">Recipient address</label>
                                                                <input
                                                                    name="recipient_address" className="f-control"
                                                                    type="text"
                                                                    placeholder="Eg. 123 Street Road"
                                                                    {...register("recipient_address")}
                                                                />
                                                                {errors.recipient_address && <span
                                                                    className="f-error">{errors.recipient_address.message}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.recipient_city})}>
                                                                <label htmlFor="recipient_city">City</label>
                                                                <input
                                                                    name="recipient_city" className="f-control"
                                                                    type="text"
                                                                    placeholder="Your City"
                                                                    {...register("recipient_city")}
                                                                />
                                                                {errors.recipient_city && <span
                                                                    className="f-error">{errors.recipient_city.message}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.recipient_postal_code})}>
                                                                <label htmlFor="recipient_postal_code">Postal Code</label>
                                                                <input
                                                                    name="recipient_postal_code" className="f-control"
                                                                    type="text"
                                                                    placeholder="Enter post or ZIP code"
                                                                    {...register("recipient_postal_code")}
                                                                />
                                                                {errors.recipient_postal_code && <span
                                                                    className="f-error">{errors.recipient_postal_code.message}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.recipient_state})}>
                                                                <label htmlFor="recipient_state">State</label>
                                                                <input
                                                                    name="recipient_state" className="f-control"
                                                                    type="text"
                                                                    placeholder="Your State"
                                                                    {...register("recipient_state")}
                                                                />
                                                                {errors.recipient_state && <span
                                                                    className="f-error">{errors.recipient_state.message}</span>}
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {watchRecipientBank === RECIPIENT_BANK_UK_SORT_CODE &&
                                                    <>
                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.account_name})}>
                                                                <label htmlFor="account_name">Name on the
                                                                    account</label>
                                                                <input
                                                                    name="account_name" className="f-control"
                                                                    type="text"
                                                                    placeholder="Please enter name on the account"
                                                                    {...register("account_name")}
                                                                />
                                                                {errors.account_name && <span
                                                                    className="f-error">{errors.account_name.message}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="f-field">
                                                            &nbsp;
                                                        </div>

                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.account_number})}>
                                                                <label htmlFor="account_number">Account
                                                                    number</label>
                                                                <input
                                                                    name="account_number" className="f-control"
                                                                    type="text"
                                                                    placeholder="Must be between 6 and 8 digits long"
                                                                    {...register("account_number")}
                                                                />
                                                                {errors.account_number && <span
                                                                    className="f-error">{errors.account_number.message}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="f-field">
                                                            <div
                                                                className={classNames("f-field__elem", {"f-field__elem--has-error": errors.sort_code})}>
                                                                <label htmlFor="sort_code">SORT code</label>
                                                                <input
                                                                    name="sort_code" className="f-control"
                                                                    type="text"
                                                                    placeholder="Must be 6 digits long"
                                                                    {...register("sort_code")}
                                                                />
                                                                {errors.sort_code && <span
                                                                    className="f-error">{errors.sort_code.message}</span>}
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                <div className="f-field f-field--full">
                                                    <button
                                                        type="submit"
                                                        className="c-btn c-btn--large c-btn--primary"
                                                    >
                                                        NEXT
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AdvisorRequestPayment;