import React, { useState, useRef, useEffect } from "react"
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import classNames from "classnames";
import ProgressBar from "../progress-bar/review_proposal_progress_bar";
import {
    CONFLICT_OF_INTEREST_STEP,
} from "../progress-bar/constants";
import {agreeCoi} from "../../api/sage";

const PROPOSAL_PARTNERS_COLUMN_NAME = 'Partners';
const PM_COLUMN_NAME = 'PM Email';

export const ConflictOfInterest = ({ formSubmitCb, expert, proposal, token }) => {
    const { register, handleSubmit, control, formState: { errors }, setError } = useForm();
    const [state, setState] = useState({
        isSubmitted: false,
        isError: false,
        error: '',
      });

    const formRef = useRef(null);

    const agreeCoiMutation = useMutation(() => {
        return agreeCoi(token);
    });

    const onSubmit = (data) => {
        agreeCoiMutation.mutate();
    }

    useEffect(() => {
        if (agreeCoiMutation.isSuccess) {
            formSubmitCb();
        }
    }, [agreeCoiMutation.isSuccess]);

    return (
        <>
            <div className="c-proposal-review" ref={formRef}>
                <div className="container">
                    <div className="row">
                        <div className="c-proposal-review__form">
                            <ProgressBar currentStep={CONFLICT_OF_INTEREST_STEP} />
                            <div className="f-section">
                                <h3>Proposal partners</h3>
                                <p>The following entities are cited in the proposal</p>
                                <p>&nbsp;</p>
                                <p className="proposal-partners" style={{whiteSpace: "pre-wrap"}}>
                                    {proposal[PROPOSAL_PARTNERS_COLUMN_NAME]}
                                </p>
                                <p>&nbsp;</p>
                                <form className="c-form" onSubmit={handleSubmit(onSubmit)}>
                                    {state.isError && (
                                      <div className="f-messages">
                                          <div className="f-messages__alert f-messages__alert--error">
                                              <p>{state.error}</p>
                                          </div>
                                      </div>
                                    )}

                                    <div className="row">
                                        <div className="f-field f-field--full">
                                            <div className={classNames("f-field__elem checkbox",{"f-field__elem--has-error": errors.agree_terms}, "tcs")}>
                                                <input
                                                    className="f-control"
                                                    type="checkbox"
                                                    {...register("agree_terms", {required: "This field is required"})}
                                                    id={"agree_terms"}
                                                />
                                                <label htmlFor={"agree_terms"}>
                                                    I hereby acknowledge that I have no conflict of interest with the parties involved in the submission and execution of this proposal
                                                </label>
                                                {errors.agree_terms && <span className="f-error">{errors.agree_terms.message}</span>}
                                            </div>
                                        </div>

                                        <div className="f-field f-field--full">
                                            <button
                                                type="submit"
                                                className="c-btn c-btn--large c-btn--primary"
                                                disabled={agreeCoiMutation.isLoading}
                                            >
                                                NEXT
                                            </button>
                                            {agreeCoiMutation.isLoading && <p>Loading...</p>}
                                            {agreeCoiMutation.isError && <span className="f-error">An error has occurred. Please try again.</span>}
                                        </div>
                                    </div>

                                    <p>
                                        <br/>
                                        If you are unable to proceed, please contact me (<a href={`mailto:${proposal[PM_COLUMN_NAME]}`} target='_blank'>{proposal[PM_COLUMN_NAME]}</a>)
                                        as there may be an opportunity for you to complete a different proposal review
                                    </p>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ConflictOfInterest;